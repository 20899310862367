/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { twMerge } from "tailwind-merge";

const onFait = [
  { name: "Prestations", href: "/prestations" },
  { name: "Nos clients", href: "/clients" },
];
const onEst = [
  {
    name: "Agence",
    href: "/agence",
  },
  {
    name: "Communauté",
    href: "/communaute",
  },
];

const Header: React.FC = ({ children }) => {
  const [isOnFaitButtonHover, setIsOnFaitButtonHover] =
    React.useState<boolean>(false);
  const [isOnEstButtonHover, setIsOnEstButtonHover] =
    React.useState<boolean>(false);

  return (
    <Popover className="fixed top-0 z-10 w-full bg-white shadow-md rounded-b-2xl">
      {({ open }) => (
        <div className="px-4 lg:container">
          <div className="max-w-7xl">
            <div className="flex items-center justify-between h-16">
              {/* menu burger for mobile */}
              <div className="-my-2 -mr-2 lg:hidden">
                <Popover.Button
                  className="inline-flex items-center justify-center p-2 bg-white rounded-md text-primary"
                  key="pop_button_burger"
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              {/* logo */}
              <div className="flex items-center justify-start h-16 lg:w-0 lg:flex-1">
                <a href="/">
                  <span className="sr-only">Donut panic</span>
                  <StaticImage
                    src="../../images/logos/donut-panic-3xl.png"
                    alt="logo"
                    height={34}
                  />
                </a>
              </div>

              <Popover.Group
                as="nav"
                className="items-stretch justify-end hidden lg:flex"
              >
                {/* on fait */}
                <Popover>
                  <button
                    className={twMerge(
                      isOnFaitButtonHover
                        ? "text-white bg-primary"
                        : "text-primary bg-white hover:text-primaryDark",
                      "inline-flex items-center text-xl font-poppins tracking-wide px-4 h-16"
                    )}
                    key="pop_button_onFait"
                    aria-expanded={isOnFaitButtonHover}
                    onMouseEnter={() => setIsOnFaitButtonHover(true)}
                    onMouseLeave={() => setIsOnFaitButtonHover(false)}
                  >
                    <span className="py-4">On fait</span>
                    <ChevronDownIcon className="h-5 ml-2" />
                  </button>

                  <Transition
                    show={isOnFaitButtonHover}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      static
                      className="absolute z-10 px-2 pt-3 -ml-4 sm:px-0"
                      onMouseEnter={() => setIsOnFaitButtonHover(true)}
                      onMouseLeave={() => setIsOnFaitButtonHover(false)}
                    >
                      <div className="flex justify-center">
                        <div className="w-0 h-0 border-white shadow-lg border-l-16 border-r-16 border-b-16 border-l-transparent border-r-transparent"></div>
                      </div>
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-primaryDark ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {onFait.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="flex items-center p-3 -m-3 bg-white hover:bg-primary text-primary hover:text-white"
                            >
                              <p className="text-base tracking-wide font-poppins">
                                {item.name}
                              </p>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>

                {/* on est */}
                <Popover>
                  <button
                    className={twMerge(
                      isOnEstButtonHover
                        ? "text-white bg-primary"
                        : "text-primary bg-white hover:text-primaryDark",
                      "inline-flex text-xl font-poppins items-center px-4 h-16"
                    )}
                    key="pop_button_onEst"
                    aria-expanded={isOnEstButtonHover}
                    onMouseEnter={() => setIsOnEstButtonHover(true)}
                    onMouseLeave={() => setIsOnEstButtonHover(false)}
                  >
                    <span className="py-4 pl-2">On est</span>
                    <ChevronDownIcon className="h-5 ml-2" />
                  </button>

                  <Transition
                    show={isOnEstButtonHover}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      static
                      className="absolute z-10 px-2 pt-3 -ml-4 sm:px-0"
                      onMouseEnter={() => setIsOnEstButtonHover(true)}
                      onMouseLeave={() => setIsOnEstButtonHover(false)}
                    >
                      <div className="flex justify-center">
                        <div className="w-0 h-0 border-white border-l-16 border-r-16 border-b-16 border-l-transparent border-r-transparent"></div>
                      </div>
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-primaryDark ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {onEst.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="flex items-center p-3 -m-3 bg-white hover:bg-primary text-primary hover:text-white"
                            >
                              <p className="text-base tracking-wide font-poppins">
                                {item.name}
                              </p>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>

                <Link
                  to="/blog"
                  className="inline-flex items-center px-4 text-xl tracking-wide text-primary hover:bg-primary hover:text-white font-poppins"
                >
                  Blog
                </Link>
              </Popover.Group>
              {children}
            </div>
          </div>

          <Popover.Overlay
            className={`${
              open ? "opacity-30 fixed inset-0" : "opacity-0"
            } bg-gray-900`}
          />
          {/* panel mobile */}
          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="fixed inset-0 top-0 pr-8 transition origin-left lg:hidden"
            >
              <div className="h-full bg-white divide-y-2 shadow-lg rounded-tr-2xl rounded-br-2xl ring-1 ring-primaryDark ring-opacity-5 divide-gray-50">
                <div className="flex flex-col h-full p-5">
                  <div className="flex justify-between">
                    <StaticImage
                      src="../../images/logos/donut-panic-xs.png"
                      alt="logo"
                      height={40}
                    />
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center p-2 bg-white rounded-md text-primary">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <nav className="flex flex-col content-around mt-6 grow justify-evenly ">
                    <Link
                      key="nos prestations"
                      to="/prestations"
                      className="flex items-center justify-center tracking-widest rounded-md grow font-poppins text-primary active:bg-[rgba(236,236,236,0.9)] uppercase font-semibold"
                    >
                      Nos presta
                    </Link>
                    <div className="h-0.5 w-28 border-b border-primary self-center"></div>
                    <Link
                      key="nos clients"
                      to="/clients"
                      className="flex items-center justify-center tracking-widest rounded-md grow font-poppins text-primary active:bg-[rgba(236,236,236,0.9)] uppercase font-semibold"
                    >
                      Nos clients
                    </Link>
                    <div className="h-0.5 w-28 border-b border-primary self-center"></div>
                    <Link
                      key="agence"
                      to="/agence"
                      className="flex items-center justify-center tracking-widest rounded-md grow font-poppins text-primary active:bg-[rgba(236,236,236,0.9)] uppercase font-semibold"
                    >
                      L'agence
                    </Link>
                    <div className="h-0.5 w-28 border-b border-primary self-center"></div>
                    <Link
                      key="la communauté"
                      to="/communaute"
                      className="flex items-center justify-center tracking-widest rounded-md grow font-poppins text-primary active:bg-[rgba(236,236,236,0.9)] uppercase font-semibold"
                    >
                      La communauté
                    </Link>
                    <div className="h-0.5 w-28 border-b border-primary self-center"></div>
                    <Link
                      key="notre blog"
                      to="/blog"
                      className="flex items-center justify-center tracking-widest rounded-md grow font-poppins text-primary active:bg-[rgba(236,236,236,0.9)] uppercase font-semibold"
                    >
                      Notre blog
                    </Link>
                  </nav>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

export default Header;
