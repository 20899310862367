import * as React from "react";
import { twMerge } from "tailwind-merge";
import axios from "axios";
import { mergeDeepRight } from "ramda";

import InputTextarea from "./InputTextarea";
import InputText from "./InputText";
import Button, { ButtonVariant } from "../Button";
import type { IContactForm, IFormState, IServerState } from "./types";
import { validateFormData, extractSimpleData } from "./formManager";

const FORM_END_POINT =
  "https://getform.io/f/0824d9a5-715d-45ee-9eb5-57b82987540d";

const defaultFormState: IFormState = {
  firstName: {
    value: "",
    error: { inError: false },
    rules: [{ require: true, message: "Votre prénom est obligatoire." }],
  },
  lastName: {
    value: "",
    error: { inError: false },
    rules: [
      {
        require: true,
        message: "Votre nom est obligatoire.",
      },
    ],
  },
  company: {
    value: "",
    error: { inError: false },
    rules: [
      {
        require: false,
        message: "",
      },
    ],
  },
  email: {
    value: "",
    error: { inError: false },
    rules: [
      {
        require: true,
        message: "Votre email est obligatoire.",
      },
      {
        pattern: /^\S+@\S+$/i,
        message:
          "Une adresse email doit être de la forme contact@donutpanic.com",
      },
    ],
  },
  phone: {
    value: "",
    error: { inError: false },
    rules: [
      {
        require: false,
        message: "",
      },
    ],
  },
  message: {
    value: "",
    error: { inError: false },
    rules: [
      {
        require: true,
        message: "Votre message est obligatoire.",
      },
    ],
  },
};

const ContactForm = ({ onValidate }: IContactForm) => {
  const [serverState, setServerState] = React.useState<IServerState>({
    submitting: false,
    status: undefined,
  });

  const [formState, setFormState] = React.useState(defaultFormState);

  React.useEffect(() => {
    const savedFormState = localStorage.getItem("contact_form_state");

    if (savedFormState !== null) {
      const hydratedFormState = mergeDeepRight(
        defaultFormState,
        JSON.parse(savedFormState)
      );

      setFormState(hydratedFormState);
    }
  }, []);

  const saveFormState = (formState: IFormState) => {
    const values = Object.keys(formState).reduce(
      (previous: Object, key: string) => {
        return { ...previous, ...{ [key]: { value: formState[key].value } } };
      },
      {}
    );

    localStorage.setItem("contact_form_state", JSON.stringify(values));
    setFormState(formState);
  };

  const clearFormState = () => {
    localStorage.removeItem("contact_form_state");
    setFormState(defaultFormState);
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    const {
      formState: validatedFormState,
      isFormValid,
    }: { formState: IFormState; isFormValid: boolean } =
      validateFormData(formState);

    if (!isFormValid) {
      saveFormState(validatedFormState);

      return;
    }

    setServerState({ submitting: true, status: undefined });
    axios
      .post(FORM_END_POINT, extractSimpleData(formState))
      .then(() => {
        handleServerResponse(
          true,
          // @ts-ignore
          event?.target,
          "Nous avons bien reçu votre message. Merci !"
        );

        setTimeout(() => {
          onValidate();
        }, 3000);
      })
      .catch(() => {
        handleServerResponse(
          false,
          undefined,
          "Un problème est survenue lors de l'envoi de votre message. Veuillez réssayer plus tard."
        );
      });
  };

  const handleServerResponse = (
    ok: boolean,
    form?: HTMLFormElement,
    msg?: string
  ) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });

    if (ok) {
      form?.reset();
      clearFormState();
    }
  };

  const handleChange = (
    name: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const updatedFormState = mergeDeepRight(formState, {
      [name]: {
        value: event.target.value,
        error: {
          inError: false,
          message: "",
        },
      },
    });

    saveFormState(updatedFormState);
  };

  return (
    <div className="font-verdana text-primaryDark">
      <p className="text-xs lg:text-base">
        Nous sommes disponibles et à votre écoute afin d'échanger sur votre
        projet.
        <br />
        <br />
        Partagez-nous votre besoin et nous vous donnons notre regard de
        spécialistes de l’UX Design et de la psychologie.
        <br />
        <br />
        Vous pouvez nous laisser un message avec vos coordonnées et nous vous
        répondrons dans les meilleurs délais.
      </p>
      <div className="flex flex-col p-4 lg:p-8">
        {serverState.status && (
          <div
            className={twMerge(
              "px-2 py-4 border rounded",
              serverState.status.ok
                ? "text-emerald-600 border-emerald-500"
                : "text-red-600 border-red-600"
            )}
          >
            {serverState.status.ok
              ? "Merci de votre message"
              : serverState.status.msg}
          </div>
        )}
        {serverState.status?.ok !== true && (
          <form onSubmit={handleSubmit} id="contactForm" noValidate>
            <InputText
              id="first-name"
              name="firstName"
              label="Prénom*"
              autoComplete="given-name"
              error={formState.firstName.error}
              onChange={handleChange}
              value={formState.firstName.value}
            />

            <InputText
              id="last-name"
              name="lastName"
              label="Nom*"
              autoComplete="family-name"
              error={formState.lastName.error}
              onChange={handleChange}
              value={formState.lastName.value}
            />

            <InputText
              id="company"
              name="company"
              label="Société"
              autoComplete="company"
              error={formState.company.error}
              onChange={handleChange}
              value={formState.company.value}
            />

            <InputText
              id="email-address"
              name="email"
              label="Adresse e-mail*"
              autoComplete="email"
              error={formState.email.error}
              onChange={handleChange}
              value={formState.email.value}
            />

            <InputText
              id="phone"
              name="phone"
              label="Téléphone"
              autoComplete="phone"
              error={formState.phone.error}
              onChange={handleChange}
              value={formState.phone.value}
            />

            <InputTextarea
              id="message"
              name="message"
              label="Comment peut-on vous aider ?*"
              rows={5}
              value={formState.message.value}
              onChange={handleChange}
              error={formState.message.error}
            />

            <Button
              variant={ButtonVariant.primary}
              text="Envoyer"
              onClick={() => handleSubmit()}
              className="mt-4"
              disabled={serverState.submitting}
            />
          </form>
        )}
      </div>
      {serverState.status?.ok !== true && (
        <p className="text-xs mt-14 lg:mt-20 lg:text-base">
          Les champs marqués d’une astérisque (*) sont obligatoires.
        </p>
      )}
    </div>
  );
};

export default ContactForm;
