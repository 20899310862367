import * as React from "react";
import Footer from "../Footer/Footer";
import Meta from "../Meta/Meta";
import Header from "../Header";
import Button, { ButtonVariant } from "../Button";
import { twMerge } from "tailwind-merge";
import { useLayout } from "./layout-context";
import Modal from "../Modal";
import ContactForm from "../ContactForm";
import Title, { TitleVariant } from "../Title";
import { StaticImage } from "gatsby-plugin-image";

interface Layout {
  title: string;
  metaTitle: string;
  metaTitleSuffix?: string;
  banner?: string;
  description?: string;
}

const Layout: React.FC<Layout> = ({
  title,
  metaTitle,
  metaTitleSuffix,
  banner,
  description,
  children,
}) => {
  const [setShowModal, showModal] = useLayout();

  return (
    <div className="font-sans text-main text-primaryDark">
      <Modal
        isOpen={showModal}
        title="Contactez-nous"
        onClose={() => setShowModal(false)}
      >
        <ContactForm onValidate={() => setShowModal(false)} />
      </Modal>
      <Meta
        title={metaTitle}
        titleSuffix={metaTitleSuffix}
        description={description}
      />
      {/* banner */}
      <div
        className={twMerge(
          "w-full bg-primaryD2",
          banner ?? "lg:bg-header-pattern"
        )}
        style={{
          backgroundPositionY: "18%",
        }}
      >
        <div className="bg-black bg-opacity-50">
          <div className="h-16"></div>
          <div className="flex items-center justify-center -mt-1 lg:container lg:h-40">
            <Title
              variant={TitleVariant.h1}
              text={title}
              className="px-4 py-12 text-center text-white"
            />
            <div className="absolute right-0 lg:hidden">
              <StaticImage
                objectFit="contain"
                height={48}
                alt="petit donut caché au bord de l'écran"
                src="../../images/icons/donut.png"
              />
            </div>
          </div>
        </div>
      </div>
      <Header>
        <Button
          className="lg:px-4"
          textClassName="text-xl hover:bg-primaryDark"
          variant={ButtonVariant.primary}
          text="Contact"
          onClick={() => setShowModal(true)}
        />
      </Header>
      {/* content */}
      <div className="px-4 lg:container">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
