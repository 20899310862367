/* This example requires Tailwind CSS v2.0+ */
import * as React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

interface Modal {
  isOpen: boolean;
  title: string;
  onClose: () => void;
}

const Modal: React.FC<Modal> = ({ isOpen, title, onClose, children }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        // initialFocus={cancelButtonRef}
        onClose={() => onClose()}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative px-5 py-8 overflow-hidden text-left transition-all bg-white rounded-lg shadow-xl sm:max-w-lg lg:p-8 lg:w-3/5 lg:max-w-none">
              <XIcon
                className="float-right w-8 h-8 cursor-pointer text-primary"
                aria-hidden="true"
                onClick={() => onClose()}
              />
              <Dialog.Title>
                <div className="text-xl tracking-wide lg:text-3xl text-primary font-poppins">
                  {title}
                </div>
              </Dialog.Title>
              <div className="mt-10">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
