import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

interface Meta {
  title: string;
  titleSuffix?: string;
  description?: string;
}

const Meta = ({ title, titleSuffix, description }: Meta) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            social {
              discord
            }
          }
        }
      }
    `
  );

  const metaDescription: string = description ?? site.siteMetadata.description;
  const defaultTitle = site.siteMetadata.title;
  const computedTitle = title + " - " + (titleSuffix ?? defaultTitle);

  return (
    <Helmet title={computedTitle} titleTemplate={`%s`}>
      <html lang="fr" className="no-js" />
      <meta name="description" content={metaDescription} />
      {/* The unique description of the page, for example, displayed on search result pages. It can be any length, but search engines truncate snippets to ~155–160 characters. */}
      <meta property="og:title" content={`${title} | ${defaultTitle}`} />
      {/* The unique title of the page. Used by URL scrapers on social media platforms like Twitter or Facebook. */}
      <meta property="og:description" content={metaDescription} />
      {/* The unique description of the page. Used by URL scrapers on social media platforms like Twitter or Facebook. */}
      <meta
        property="og:image"
        content={`${site.siteMetadata.siteUrl}/image.jpg`}
      />
      {/* 
        The image displayed when you share the link to a page on social media, chat applications, or other sites that scrape URLs.
        Ideally, it should be a square image with the important content placed in the middle of the square in a rectangle 
        with a 2:1 ratio. This will make sure that the image will look good in cards with rectangle and square shaped images. 
    */}
      <meta property="og:image:alt" content="Image description" />
      {/* A description of the image. Don’t use this meta tag if the image is purely decorative and doesn’t provide any meaningful information. Screen readers ignore the image, if we don’t provide alt text. */}
      <meta property="og:locale" content="fr_FR" />
      {/* An optional Open Graph property, but recommended. It defines the natural language of the page. */}
      <meta property="og:type" content="website" />
      {/* The type of content you’re sharing, e.g. website, article, or video.movie. A required property for valid Open Graph pages. */}
      <meta
        property="og:url"
        content={`${site.siteMetadata.siteUrl}/page`}
      ></meta>
      {/* The canonical URL of the page. A required property for valid Open Graph pages. */}
      <meta name="twitter:card" content={metaDescription} />
      {/* This meta tag defines how cards will look when shared on Twitter. There are two options for websites, summary and summary_large_image. */}
      <script type="module">
        document.documentElement.classList.remove('no-js');
        document.documentElement.classList.add('js');
      </script>
      <link rel="canonical" href={`${site.siteMetadata.siteUrl}/page`} />
      {/* Use the canonical link element to prevent SEO issues caused by duplicate content by specifying the original source for pages that are available on multiple URLs. */}
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      {/* A 32×32px favicon for legacy browsers. It should live in the root of your website. */}
      {/* <link rel="icon" href="/favicon.svg" type="image/svg+xml" /> */}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />

      {/* Most modern browser support SVG favicons. The benefits of the favicon.svg are that it looks better when it’s scaled, because it’s a vector and not raster image, and we can add HTML and CSS to the SVG, which means that we can support dark mode. */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      {/* The 180×180px icon Apple devices will use if you add the page to your home screen. */}
      <link rel="manifest" href="/site.webmanifest" />
      {/* For Android devices we need a .webmanifest file, which provides browsers with the information where the icons needed for the home screen and the splash screen for PWAs are located. */}
      <meta name="theme-color" content="#FFFFFF" />
      {/* theme-color provides browsers with a CSS color to customize the display of the page or of the surrounding user interface. */}
    </Helmet>
  );
};

export default Meta;
