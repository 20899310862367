import * as React from "react";
import { twMerge } from "tailwind-merge";

interface Title {
  text: string;
  variant: TitleVariant;
  className?: string;
}

export enum TitleVariant {
  h1 = "font-poppins font-extrabold text-xl tracking-wide text-primaryDark leading-7 lg:text-3xl lg:leading-9",
  h2 = "font-poppins font-light text-2xl text-primaryDark lg:font-medium lg:text-3xl lg:leading-10",
  h3 = "font-poppins font-semibold text-xl text-primaryDark lg:text-2xl lg:leading-9",
  h4 = "font-work font-medium text-base leading-5 text-primaryDark lg:text-xl",
}

const Title = ({ text, variant, className }: Title) => {
  const calulatedClassName = twMerge(variant, className);

  switch (variant) {
    case TitleVariant.h1:
      return <h1 className={calulatedClassName}>{text}</h1>;
    case TitleVariant.h2:
      return <h2 className={calulatedClassName}>{text}</h2>;
    case TitleVariant.h3:
      return <h3 className={calulatedClassName}>{text}</h3>;
    case TitleVariant.h4:
      return <h4 className={calulatedClassName}>{text}</h4>;
    default:
      return <div className={calulatedClassName}>{text}</div>;
  }
};

export default Title;
