import * as React from "react";
import { twMerge } from "tailwind-merge";

interface InputProps {
  id: string;
  name: string;
  label: string;
  error: {
    inError: boolean;
    message?: string;
  };
  value: string;
  onChange: (name: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  className?: string;
}

const InputTextarea = ({
  id,
  name,
  label,
  error,
  onChange,
  value,
  rows,
  className,
}: InputProps) => {
  const [state, setState] = React.useState<"default" | "focused" | "filled">(
    "default"
  );
  React.useEffect(() => handleBlur(), [value]);

  const [hover, setHover] = React.useState<boolean>(false);

  const handleBlur = () => {
    const stateValue =
      // @ts-ignore
      document.getElementById(id)?.value.length > 1 ? "filled" : "default";

    setState(stateValue);
  };

  return (
    <div
      className="relative flex flex-col pt-5 mt-7"
      onFocus={() => setState("focused")}
      onBlur={handleBlur}
    >
      <label
        htmlFor={id}
        className={twMerge(
          "absolute",
          hover ? "text-primaryD2" : "text-primary",
          state == "default"
            ? "bottom-0 lg:text-xl text-lg"
            : "top-0 lg:text-sm text-xs",
          !!error.inError && "text-secondary text-sm lg:text-xs bottom-8"
        )}
      >
        {error.inError && error.message ? `Erreur : ${error.message}` : label}
      </label>
      <textarea
        id={id}
        name={name}
        onChange={(e) => onChange(name, e)}
        value={value}
        rows={state == "default" ? 1 : rows}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={twMerge(
          "sm:text-sm block",
          "border-white border-b-primary border-b-2 w-full py-1 px-0",
          "hover:border-b-primaryD2 focus:ring-0 focus:border-white focus:border-b-primary",
          className
        )}
      />
    </div>
  );
};

export default InputTextarea;
