import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonVariant } from "../Button";
import { twMerge } from "tailwind-merge";

const Footer = ({ className }: { className?: string }) => {
  return (
    <div
      className={twMerge(
        "w-full px-6 py-4 mt-8 text-xs tracking-wide text-white font-rambla bg-primary rounded-t-2xl lg:text-base lg:py-6 lg:px-0 lg:mt-10",
        className
      )}
    >
      <div className="flex lg:container flex-stretch">
        <div className="grow">
          <div className="flex flex-col justify-between h-full">
            <Link
              to="/prestations"
              className="inline-block underline hover:no-underline"
            >
              Nos prestations
            </Link>
            <Link to="/clients" className="underline hover:no-underline">
              Nos réalisations
            </Link>
            <Link
              to="/mentions-legales"
              className="underline lg:hidden hover:no-underline"
            >
              Mentions légales
            </Link>
          </div>
        </div>
        <div className="text-center grow">
          <p className="">Rejoignez notre communauté !</p>
          <div className="flex items-center justify-center mx-4 mt-2">
            <span
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/donut-panic-agency",
                  "_blank"
                );
              }}
            >
              <StaticImage
                alt="LinkedIn"
                src="../../images/logos/linkedin.png"
                height={36}
                className="cursor-pointer"
                title="join us on linked In"
              />
            </span>
            <Button variant={ButtonVariant.discord} className="ml-2" text="" />
          </div>
        </div>
        <div className="items-end justify-end hidden grow lg:flex">
          <Link
            to="/mentions-legales"
            className="underline lg:inline hover:no-underline"
          >
            Mentions légales
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
