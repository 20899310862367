import * as React from "react";
import { twMerge } from "tailwind-merge";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

interface Button {
  text: string;
  variant: ButtonVariant;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  to?: string;
  replace?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  textClassName?: string;
}

export enum ButtonVariant {
  primary = "tracking-wide text-white bg-primaryD1 hover:bg-primary active:bg-primaryD2 font-poppins focus:border-primaryL1 focus:shadow-button",
  secondary = "tracking-wide text-primaryD1 text-base border-2 border-primaryD1 font-poppins hover:text-primary hover:border-primary focus:border-primaryL1 focus:shadow-button active:bg-greyLight",
  discord = "tracking-wide text-base text-discord font-poppins w-max px-4 bg-white cursor-pointer rounded-3xl",
}

const Button = ({
  text,
  variant,
  onClick,
  to,
  replace = false,
  icon,
  disabled = false,
  className,
  textClassName,
}: Button) => {
  const getDisabledClassNames: () => string = () => {
    return variant === ButtonVariant.primary
      ? "bg-greyDark active:bg-greyDark focus:border-none focus:shadow-none hover:bg-greyDark text-gray-200 cursor-not-allowed"
      : "border-greyDark bg-gray-200 cursor-not-allowed text-greyDark hover:border-greyDark hover:text-greyDark";
  };

  const outerClassNames = twMerge("flex w-max items-center h-12", className);
  const innerClassNames = twMerge(
    "flex items-center px-5 text-base rounded-3xl h-9",
    variant,
    textClassName
  );

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    onClick && onClick(e);
  };

  if (variant === ButtonVariant.discord) {
    return (
      <a href="#" onClick={handleClick} className={outerClassNames}>
        <span
          title="Rejoignez-nous sur Discord"
          className={innerClassNames}
          onClick={() => {
            window.open("https://discord.gg/Dgr9V2w4fS", "_blank");
          }}
        >
          {text ? (
            <>
              <StaticImage
                alt="Discord"
                src="../../images/logos/dicord-logo-only.svg"
                height={22}
                className="my-auto"
              />
              <span className="ml-2 align-middle">{text}</span>
            </>
          ) : (
            <StaticImage
              alt="Discord"
              src="../../images/logos/discord.svg"
              height={22}
              className="my-auto"
            />
          )}
        </span>
      </a>
    );
  }

  if (disabled) {
    return (
      <span className={outerClassNames}>
        <span className={twMerge(innerClassNames, getDisabledClassNames())}>
          {icon && icon} <span className="align-middle">{text}</span>
        </span>
      </span>
    );
  }

  if (to) {
    return (
      <Link to={to} className={outerClassNames} replace={replace}>
        <span className={innerClassNames}>
          {icon && icon} <span className="align-middle">{text}</span>
        </span>
      </Link>
    );
  }

  return (
    <a href="#" onClick={handleClick} className={outerClassNames}>
      <span className={innerClassNames}>
        {icon && icon} <span className="align-middle">{text}</span>
      </span>
    </a>
  );
};

export default Button;
