import { mergeDeepRight } from "ramda";

import type { IFormState, ISimpleDataForm } from "./types";

const validateFormData = (data: IFormState) => {
  const validatedData = Object.entries(data).map((element) => {
    const errorMessageList = element[1].rules
      .map((rule) => {
        if ("require" in rule && rule.require && element[1].value.length < 1) {
          return rule.message;
        }
        if ("pattern" in rule && !rule.pattern.test(element[1].value)) {
          return rule.message;
        }

        return undefined;
      })
      .filter((el) => el !== undefined);

    return {
      key: element[0],
      value:
        errorMessageList.length > 0
          ? mergeDeepRight(element[1], {
              error: {
                inError: true,
                message: errorMessageList[0],
              },
            })
          : mergeDeepRight(element[1], {
              error: {
                inError: false,
                message: "",
              },
            }),
    };
  });

  return {
    isFormValid:
      validatedData.filter((element) => element.value.error.inError).length ===
      0,
    formState: validatedData.reduce((obj, { key, value }) => {
      return Object.assign(obj, { [key]: value });
    }, {}),
  };
};

const extractSimpleData = (data: IFormState): ISimpleDataForm => {
  let simpleData = {};
  for (var key in data) {
    // @ts-ignore
    simpleData[key] = data[key].value;
  }

  return simpleData;
};

export { validateFormData, extractSimpleData };
